@import '../../styles/variables';

.container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 20px;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0 10px;
  }
}
