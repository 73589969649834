.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  padding: 20px 0;
}
