@import '../../styles/variables';

.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  color: $text-secondary;
  background: $bg-secondary;

  a {
    color: $text-secondary;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  img {
    display: block;
    height: 100%;
    max-height: 50px;
    width: auto;
  }
}

.headerContent {
  display: flex;
  align-items: center;
  height: 70px;
}

.headerContentRight {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.searchBarContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 600px;

  .closeButton {
    margin-right: 20px;
  }

  .searchButton,
  .displayNone {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .searchBarContainer {
    .searchBarDesktop {
      display: none;
    }

    .searchButton {
      display: inline-block;
    }
  }
}
