@import '../../styles/variables';

// General styles

.img {
  display: block;
  width: 100%;
  height: auto;
}

.details {
  overflow: hidden;
}

.title,
.text {
  color: $text-primary;
}

.title {
  font-size: 1.6rem;
  font-weight: 600;
  max-height: 5rem;
  line-height: 1.2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.meta {
  display: flex;
  flex-flow: row wrap;
  margin-top: 5px;

  .text:after {
    content: '•';
    margin: 0 5px;
  }

  .text:last-child::after {
    content: '';
    margin: 0;
  }
}

.description {
  margin-top: 10px;
  word-break: break-word;
  line-height: 1.2;
  max-height: 80px;
  overflow: hidden;

  .text {
    font-size: 1.6rem;
  }
}

.text {
  margin: 0;
  font-size: 1.4rem;
}

.videoPreviewRow,
.videoPreviewColumn {
  background: $bg-primary;
}

// Displayed as a ROW!

.videoPreviewRow {
  display: flex;
  flex-flow: row wrap;

  .thumbnail {
    flex: 1;
    max-width: 360px;
    min-width: 160px;
  }

  .details {
    flex: 1;
    min-width: 140px;
    padding-left: 20px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

// Displayed as COLUMN!

.videoPreviewColumn {
  display: flex;
  flex-direction: column;

  .description {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  // Row !
  .videoPreviewRow {
    .details {
      flex: 2;
      padding-left: 10px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .description {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 320px) {
  // ROW!
  .videoPreviewRow {
    flex-flow: column;

    .details {
      padding-left: 0;
    }

    .title {
      margin-top: 5px;
    }
  }
}
