// Container width
$max-width: 1400px;

// Background colors
$bg-primary: #f8f8ff;
$bg-secondary: #011447;
$bg-light: #e7eff5;
$bg-dark: #053f82;

// Text colors
$text-primary: #000105;
$text-secondary: #f8f8ff;
$text-light: #053f82;
$text-dark:  #011447;

// Fonts
$font-primary: 'Cabin', sans-serif;
$font-secondary: 'Open Sans', sans-serif;
