.videoDescription {
  word-break: break-word;

  p,
  a {
    margin: 0;
    min-height: 1rem; /* Spacer - empty paragraph */
  }

  .showMoreButton {
    padding-left: 0;
    text-transform: uppercase;
    overflow-anchor: none;
  }
}

@media only screen and (max-width: 600px) {
  .videoDescription {
    p,
    a {
      font-size: 1.4rem;
    }
  }
}
