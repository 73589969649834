.default {
  color: #ff0000;
}

.center {
  color: #ff0000;
  display: flex;
  justify-content: center;
  text-align: center;
}
