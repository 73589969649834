@import '../../styles/variables';

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 20px;
  font-family: $font-primary;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  outline: 0;
}

.icon {
  margin-right: 10px;
}

.button-primary {
  background: $bg-secondary;
  color: $text-secondary;

  &:hover {
    background: $bg-dark;
  }
}

.button-secondary {
  background: $bg-primary;
  color: $text-dark;

  &:hover {
    color: $text-light;
  }
}
